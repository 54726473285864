<template>
  <el-container class="shopList ">
    <el-header class="box add">
       <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统参数配置</el-breadcrumb-item>
       <el-breadcrumb-item >平台参数</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
          <el-button  class="back_btn"  size="medium" plain @click="$router.back()">返回</el-button>
        </div>
    </el-header>
    <el-main class="p15 pt15">
           <el-form :inline="true"  status-icon ref="form"  :model="forms"  class="demo-ruleForm">
     <el-row class="box mt20  f14">
      <el-col :span="7" class="tl " >
            <el-form-item size="medium" label="平台logo：" class="w" label-width="41%" >
                 <div class="recordadd-upload">
              <el-upload 
                ref="upload"
                multiple
                v-if="imageUrl=='1234567'"
                type="file"
                :file-list="fileList"
                action="/pc/file/upload"
                list-type="picture-card"
                :before-upload="beforeAvatarUpload"
                accept="image/png,image/jpg,image/jpeg"
                :on-success="handlepoll"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :class="{disabled:uploadDisabled}"
                >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-upload 
                ref="upload"
                multiple
                type="file"
                v-else
                :file-list="fileList"
                action=""
                list-type="picture-card"
                :before-upload="beforeAvatarUpload"
                accept="image/png,image/jpg,image/jpeg"
                :http-request="(params) =>{return handlepoll(params)}"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :class="{disabled:uploadDisabled}"
                >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
              </div>
            </el-form-item>
          </el-col>
           <el-col :span="7" class="tl " >
            <el-form-item size="medium" label="平台封面：" class="w" label-width="41%" >
                 <div class="recordadd-upload">
         <el-upload 
                ref="upload"
                multiple
                v-if="imageUrl=='1234567'"
                action="/pc/file/upload"
                list-type="picture-card"
                :file-list="fileList1"
                :before-upload="beforeAvatarUpload"
                accept="image/png,image/jpg,image/jpeg"
                :on-success="handlepoll1"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove1"
                :before-remove="beforeRemove"
                :class="{disabled:uploadDisabled1}"
                >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-upload 
                ref="upload"
                multiple
                action=""
                v-else
                list-type="picture-card"
                :file-list="fileList1"
                :before-upload="beforeAvatarUpload"
                accept="image/png,image/jpg,image/jpeg"
                :http-request="(params) =>{return handlepoll1(params)}"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove1"
                :before-remove="beforeRemove"
                :class="{disabled:uploadDisabled1}"
                >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
        </div>
            </el-form-item>
          </el-col>
          
       <el-col :span="11" class="mt30" >
        <el-form-item  label="平台主标题：" size="medium" class="w" label-width="28%"  prop="platform_main_title" :rules="[{required: true,message: '请输入平台主标题', trigger: 'blur' }]">
           <el-input placeholder="平台主标题" maxlength='30' clearable v-model="forms.platform_main_title" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="11" class=" mt30" >
        <el-form-item  label="平台副标题：" size="medium" class="w" label-width="28%">
           <el-input placeholder="平台副标题" maxlength='30' clearable v-model="forms.platform_sub_title" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
         <el-col v-if="user_type==3" :span="11" class="">
        <el-form-item  label="平台版本号：" size="medium" class="w" label-width="28%" >
           <el-input placeholder="平台版本号" maxlength='30' clearable v-model="forms.platform_version" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="11" class="tl" >
         <el-form-item class="w" label-width="28%" label="版权：" size="medium" >
          <el-input placeholder="请输入版权" clearable v-model="forms.platform_copyright" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="11" class="tl " >
         <el-form-item  class="w" label-width="28%" label="技术支持：" size="medium">
           <el-input placeholder="技术支持" clearable v-model="forms.platform_support" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="11" class=" " >
        <el-form-item  label="登记号：" size="medium" class="w" label-width="28%" >
           <el-input placeholder="登记号" maxlength='30' clearable v-model="forms.platform_record" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="22" class="tl" >
        <el-button   class="ml_5"  size="medium" type="primary" @click="submitForm('form')">提交</el-button>
        </el-col>
     </el-row>
        </el-form>  
</el-main>
  </el-container>
</template>
<script>
import axios from '../../../util/api'
import { local,aa,objKeySort } from '../../../util/util'
import {imageUrl} from '../../../config/index'
import { tengxuncloud } from '@/util/aliOss'
export default {
  data () {
    return {
      imageUrl:imageUrl,
      user_type:'',
      loading:false,
      options1: [],
      show: true,
      forms:{
          platform_id:'1',
        platform_main_title:'221321',
        platform_sub_title:"",
        platform_logo_url:'',
        platform_cover_url:'',
        platform_version:'',
        platform_copyright:"",
        platform_support:"",
        platform_record:'',
      },
      fileList:[],
      fileList1:[],
     
       uploadDisabled:false,
      uploadDisabled1:false,
      dialogImageUrl: '',
        dialogVisible: false,
        pickerOptionsEnd: {
        disabledDate: time => {
          return time.getTime() > Date.now()
        }
      },
       pickerOptions: {
         disabledDate(time) {
            return time.getTime() < Date.now();
          },
        },
        user:{},
        userData:{}
    }
  },
   async mounted() {
     var that=this;
    this.userData = local.get("user");
    await this.getData(this.form.platform_id);

  },

  methods: {
       getData (params) {
          var that=this;
               axios.get('/pc/platform/single',{platform_id:params}).then((v) => {

        that.form=v.data.platform;    
        that.fileList=[{name:'',url:v.data.platform.platform_logo_url}];that.uploadDisabled=true;
        that.fileList1=[{name:"",url:v.data.platform.platform_cover_url}];that.uploadDisabled1=true
      
     })
         
    },
    submitForm(form){
         this.$refs[form].validate((valid) => {
        if (valid) {           

                axios.put('/pc/platform/update', this.forms).then((v) => {
            
      })
            }
         })
      },
      beforeAvatarUpload (file) {
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }
      return isLt10M;
    },
      handlepoll (response, file, fileList) {
         this.uploadDisabled = true;
         if(this.imageUrl=='1234567'){
           this.forms.platform_logo_url=response.data.visit_url
          }else{
           tengxuncloud(response).then((res) => {
           this.forms.platform_logo_url= res.url
           })
          }
      
   },
     handlepoll1 (response, file, fileList) {
         this.uploadDisabled1 = true;
         if(this.imageUrl=='1234567'){
           this.forms.platform_cover_url=response.data.visit_url
          }else{
           tengxuncloud(response).then((res) => {
           this.forms.platform_cover_url= res.url
           })
          }
          
    },
    
      handleRemove(file, fileList) {
        
          this.uploadDisabled = false;
          this.forms.platform_logo_url=''
      },
      handleRemove1(file, fileList) {
          this.uploadDisabled1 = false;
            this.forms.platform_cover_url=''
      },
     
     handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      beforeRemove(file, fileList){
      if (file && file.status==="success") {
      return this.$confirm(`确定移除 ${file.name}吗？`);
      }
    },
  
}
}
</script>
<style lang="scss" scoped>
.shopList{
  overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
    .active {
       background: #2EC68A;
    border-color: #2EC68A;
    color: #FFFFFF;
  }
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}
  .recordadd-title{height: 34px;line-height: 34px;border-left: 2px solid #788287}
 /deep/ .el-input-group__append .el-button--primary .el-icon-search{color: #fff}
 /deep/ .el-checkbox-button__inner{    border-left: 1px solid #DCDFE6;
    border-radius: 4px !important;}
     /deep/ .el-table.no-header .el-table__header{display: none}
  .people-message .el-form-item { margin-bottom: 0px;}
  .recordadd-upload{width: 160px;height: 160px;float: left;margin-right: 10px;
     .disabled{height: 100%;}
   /deep/ .el-upload{width: 160px;height: 160px;line-height: 160px;}
    /deep/ .el-upload-list--picture-card .el-upload-list__item{width: 160px;height: 160px;}
   }
   .health_url{
     /deep/ .el-upload--picture-card{width: 70px;height: 40px;
     background-color: transparent;
    border: none;
     .el-button--medium{position: absolute;left: 10px;margin-top: 4px;}}
/deep/ .el-upload-list--picture-card .el-upload-list__item{width: 70px;height: 40px;}
   } 
     /deep/ .el-col-7 .el-form-item__content {width: initial;}
 table .cell .is-error{padding-bottom: 20px}
 .el-table th.is-leaf, .el-table td{border-bottom: none}
   /deep/ .disabled .el-upload--picture-card {
    display: none !important;
}
/deep/ .el-table{
.el-table__body,.el-table__header{width: 100% !important;}
} 
    .el-checkbox-button{margin-bottom: 10px}
}

</style>
